import React, { ReactNode, FC } from "react";
import "../styles/widget.css";

// Define the props interface
interface WidgetProps {
  children: ReactNode; // Content to render inside the widget
  title?: string; // Optional title for the widget
  className?: string; // Optional additional CSS classes
  style?: React.CSSProperties; // Optional inline styles
}

// Functional Component with TypeScript
const WidgetContainer: FC<WidgetProps> = ({
  children,
  title,
  className = "",
  style = {},
}) => {
  return (
    <div className={`widget-container ${className}`} style={style}>
      {title && <h2 className="widget-title">{title}</h2>}
      <div className="widget-content">{children}</div>
    </div>
  );
};

export default WidgetContainer;
