import React from "react";
import Navbar from "../components/navbar";
import TransactionForm from "../components/transactionForm";
import TransactionList from "../components/transactionList";
import "../styles/dashboard.css";
import "../styles/widget.css";
import WidgetContainer from "../components/widgetContainer";

const Dashboard: React.FC = () => {
  return (
    <>
      <Navbar />
      <div>
        <h2>Your Dashboard</h2>
      </div>
      <div className="dashboard-container">
        {/* Placeholder widgets */}
        <WidgetContainer title="Add Transaction">
          <TransactionForm />
        </WidgetContainer>
        <WidgetContainer>
          <TransactionList />
        </WidgetContainer>
      </div>
    </>
  );
};

export default Dashboard;
