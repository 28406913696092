import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// Get the root element from the DOM
const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Failed to find the root element");
}

// TypeScript understands that rootElement is not null here
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Log web vitals for performance monitoring (optional)
