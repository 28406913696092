import React, { useState } from "react";
import { addTransaction } from "../services/transactionService";
import FloatingLabelInput from "./inputField";
import colours from "../styles/colours";

// Define the transaction object shape
interface Transaction {
  amount: number;
  date: Date;
  category: string;
  description: string;
  type: "expense" | "income";
}

const TransactionForm: React.FC = () => {
  const today = new Date().toISOString().split("T")[0];

  // Form state with proper types
  const [amount, setAmount] = useState<string>("");
  const [date, setDate] = useState<string>(today);
  const [category, setCategory] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<"expense" | "income">("expense");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Basic validation
      if (!amount || !date || !category || !description || !type) {
        setError("All fields are required.");
        return;
      }

      // Create a transaction object
      const transaction: Transaction = {
        amount: parseFloat(amount),
        date: new Date(date),
        category,
        description,
        type,
      };

      await addTransaction(transaction);

      setSuccess("Transaction added successfully!");
      setError("");

      // Reset form
      setAmount("");
      setDate(today);
      setCategory("");
      setDescription("");
      setType("expense");
    } catch (err) {
      console.error(err);
      setError("Failed to add transaction. Try again.");
    }
  };

  return (
    <div
      className="max-w-lg mx-auto p-6 shadow-md rounded"
      style={{ backgroundColor: colours.background }}
    >
      <h2
        className="text-2xl font-bold mb-6 text-center"
        style={{ color: colours.teal.dark }}
      >
        Add Transaction
      </h2>
      {error && (
        <p
          className="text-red-500 mb-4 text-center"
          style={{ color: colours.error }}
        >
          {error}
        </p>
      )}
      {success && (
        <p
          className="text-green-500 mb-4 text-center"
          style={{ color: colours.teal.dark }}
        >
          {success}
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <FloatingLabelInput
          label="Amount"
          type="number"
          value={amount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAmount(e.target.value)
          }
          required
        />
        <FloatingLabelInput
          label="Date"
          type="date"
          value={date}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDate(e.target.value)
          }
          required
        />
        <FloatingLabelInput
          label="Category"
          type="text"
          value={category}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCategory(e.target.value)
          }
          required
        />
        <FloatingLabelInput
          label="Description"
          type="text"
          value={description}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDescription(e.target.value)
          }
          required
        />
        <div className="mb-4">
          <label className="block mb-1" style={{ color: colours.teal.dark }}>
            Type
          </label>
          <select
            value={type}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setType(e.target.value as "expense" | "income")
            }
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2"
            style={{
              borderColor: colours.teal.dark,
              backgroundColor: colours.background,
              color: colours.teal.dark,
            }}
            required
          >
            <option value="expense">Expense</option>
            <option value="income">Income</option>
          </select>
        </div>
        <button
          type="submit"
          className="w-full py-2 rounded-lg hover:opacity-80 active:opacity-100"
          style={{
            backgroundColor: colours.teal.dark,
            color: colours.white,
          }}
        >
          Add Transaction
        </button>
      </form>
    </div>
  );
};

export default TransactionForm;
