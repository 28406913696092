import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState, FormEvent, ChangeEvent } from "react";
import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import colours from "../styles/colours";
import FloatingLabelInput from "../components/inputField";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { currentUser } = useAuth(); // Use the custom hook
  const navigate = useNavigate();

  if (currentUser) {
    navigate("/dashboard");
    return null; // Prevent rendering the login form
  }

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{ backgroundColor: colours.grey.dark }}
    >
      <div
        className="max-w-md w-full shadow-md rounded p-6"
        style={{ backgroundColor: colours.background }}
      >
        <h2
          className="text-2xl font-bold text-center mb-4"
          style={{ color: colours.grey.dark }}
        >
          Login to your account
        </h2>
        <form onSubmit={handleLogin}>
          <FloatingLabelInput
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <FloatingLabelInput
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          {error && (
            <p className="text-center mb-4" style={{ color: colours.error }}>
              {error}
            </p>
          )}
          <button
            type="submit"
            className="w-full py-2 rounded-lg hover:opacity-80 active:opacity-100"
            style={{
              backgroundColor: colours.teal.dark,
              color: colours.white,
            }}
          >
            Login
          </button>
        </form>
        <p className="text-center text-sm mt-4">
          Don’t have an account?{" "}
          <a
            href="/signup"
            className="text-blue-500 hover:underline"
            style={{
              color: colours.teal.dark,
            }}
          >
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
