import React, { ChangeEvent, FC } from "react";
import {
  createTheme,
  ThemeProvider,
  TextField,
  TextFieldProps,
} from "@mui/material";
import colours from "../styles/colours";

// Define the props interface
interface FloatingLabelInputProps
  extends Omit<TextFieldProps, "onChange" | "error"> {
  label: string; // Required label
  type?: string; // Optional input type (default is "text")
  value: string | number; // Input value (string or number)
  onChange: (event: ChangeEvent<HTMLInputElement>) => void; // Function to handle input changes
  placeholder?: string; // Optional placeholder text
  required?: boolean; // Optional required flag
  error?: string; // Custom error message (optional)
}

// Define custom theme with advanced theming
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: colours.grey.dark,
            transition: "all 0.2s ease-in-out",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: colours.teal.dark,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colours.teal.medium,
            },
            "&:hover fieldset": {
              borderColor: colours.teal.dark,
            },
            "&.Mui-focused fieldset": {
              borderColor: colours.teal.dark,
            },
          },
        },
      },
    },
  },
});

// Functional Component with TypeScript
const FloatingLabelInput: FC<FloatingLabelInputProps> = ({
  label,
  type = "text",
  value,
  onChange,
  placeholder = "",
  required = false,
  error = "",
  ...props
}) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="relative mb-4">
        <TextField
          label={label}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          error={Boolean(error)} // Convert custom error string to boolean
          helperText={error} // Display custom error message as helper text
          variant="outlined"
          fullWidth
          {...props}
        />
      </div>
    </ThemeProvider>
  );
};

export default FloatingLabelInput;
