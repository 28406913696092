import React, { createContext, useState, useEffect, ReactNode, useContext } from "react";
import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { auth } from "../firebase";

// Define the AuthContext shape
interface AuthContextType {
  currentUser: User | null; // The currently authenticated user, or null if not logged in
  handleLogout: () => Promise<void>; // Function to log out the current user
}

// Create the AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Props type for the AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

// AuthProvider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  // Logout function
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null); // Clear the current user state after sign-out
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Error logging out:", (error as Error).message);
    }
  };

  // Effect to monitor authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  // Provide the currentUser and handleLogout to children
  return (
    <AuthContext.Provider value={{ currentUser, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to safely use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
