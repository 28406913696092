import React, { useState, FormEvent, ChangeEvent } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase"; // Firebase setup
import { doc, setDoc, serverTimestamp } from "firebase/firestore"; // Firestore functions
import colours from "../styles/colours";
import { useAuth } from "../context/authContext"; // Custom hook for AuthContext
import FloatingLabelInput from "../components/inputField";

const Signup: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { currentUser } = useAuth(); // Use the custom hook to access currentUser
  const navigate = useNavigate();

  // Redirect if the user is already logged in
  if (currentUser) {
    navigate("/dashboard");
    return null; // Prevent rendering the signup form
  }

  const handleSignup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Add user to Firestore
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        createdAt: serverTimestamp(),
      });

      alert("Account created successfully!");
      setError("");
      navigate("/dashboard"); // Redirect to dashboard after signup
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (!e.target.value.includes("@")) {
      setError("Invalid email address");
    } else {
      setError("");
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setPassword(password);

    // Password validation rules
    const minLength = 8;
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (password.length < minLength) {
      setError("Password must be at least 8 characters long");
    } else if (!regex.test(password)) {
      setError(
        "Password must include uppercase, lowercase, number, and special character"
      );
    } else {
      setError("");
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{ backgroundColor: colours.teal.dark }}
    >
      <div
        className="max-w-md w-full shadow-md rounded p-6"
        style={{ backgroundColor: colours.background }}
      >
        <h2
          className="text-2xl font-bold text-center mb-4"
          style={{ color: colours.teal.dark }}
        >
          Sign Up
        </h2>
        <form onSubmit={handleSignup}>
          <FloatingLabelInput
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            error={error}
          />
          <FloatingLabelInput
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
            error={error}
          />
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full py-2 rounded-lg hover:opacity-80 active:opacity-100"
            style={{
              backgroundColor: colours.teal.dark,
              color: colours.white,
            }}
          >
            Sign Up
          </button>
        </form>
        <p className="text-center text-sm mt-4">
          Already have an account?{" "}
          <a
            href="/login"
            className="text-blue-500 hover:underline"
            style={{
              color: colours.teal.dark,
            }}
          >
            Login
          </a>
        </p>
      </div>
    </div>
  );
};

export default Signup;
