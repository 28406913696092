import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  QueryDocumentSnapshot,
  DocumentData,
} from "firebase/firestore";
import { auth, db } from "../firebase"; // Ensure Firebase is properly set up
import colours from "../styles/colours";

// Define the shape of a transaction
interface Transaction {
  id: string;
  amount: number;
  date: { seconds: number; nanoseconds: number }; // Firestore Timestamp format
  category: string;
  description: string;
  type: "expense" | "income";
}

const TransactionList: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Ensure user is authenticated
    const user = auth.currentUser;
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    // Firestore query to get transactions for the logged-in user
    const q = query(
      collection(db, "users", user.uid, "transactions"),
      orderBy("date", "desc") // Sort by date, most recent first
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedTransactions: Transaction[] = snapshot.docs.map(
          (doc: QueryDocumentSnapshot<DocumentData>) => {
            const data = doc.data();
            return {
              id: doc.id,
              amount: data.amount,
              date: data.date,
              category: data.category,
              description: data.description,
              type: data.type,
            };
          }
        );
        setTransactions(fetchedTransactions);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching transactions:", error);
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading transactions...</p>;
  }

  if (transactions.length === 0) {
    return <p>No transactions found.</p>;
  }

  return (
    <div
      className="max-w-lg mx-auto p-6 shadow-md rounded"
      style={{ backgroundColor: colours.background }}
    >
      <h2
        className="text-2xl font-bold mb-6 text-center"
        style={{ color: colours.teal.dark }}
      >
        Your Transactions
      </h2>
      <table className="table-auto w-full border-collapse border border-gray-200">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Date</th>
            <th className="border border-gray-300 px-4 py-2">Amount</th>
            <th className="border border-gray-300 px-4 py-2">Category</th>
            <th className="border border-gray-300 px-4 py-2">Description</th>
            <th className="border border-gray-300 px-4 py-2">Type</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <td className="border border-gray-300 px-4 py-2">
                {new Date(transaction.date.seconds * 1000).toLocaleString()}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                ${transaction.amount.toFixed(2)}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {transaction.category}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {transaction.description}
              </td>
              <td className="border border-gray-300 px-4 py-2 capitalize">
                {transaction.type}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionList;
