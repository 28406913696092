const colours = {
  primary: {
    light: "#60a5fa",
    DEFAULT: "#3b82f6",
    dark: "#2563eb",
  },

  secondary: {
    light: "#a78bfa",
    DEFAULT: "#8b5cf6",
    dark: "#7c3aed",
  },

  teal: {
    dark: "#3bbbaf",
    medium: "#87e2da",
    light: "#c7f1ed",
  },

  duskyrose: {
    dark: "#c1666b",
    light: "#e6b3b5",
  },

  grey: {
    dark: "#202020",
    light: "#d0d0d0",
  },

  error: "#ef4444",
  background: "#f3f4f6",
  white: "#ffffff",
} as const;

export default colours;
