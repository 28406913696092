import { db, auth } from "../firebase";
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  doc,
  deleteDoc,
  serverTimestamp,
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";

// Define the shape of a transaction
export interface Transaction {
  id?: string; // Optional ID property
  amount: number;
  date: Date;
  category: string;
  description: string;
  type: "expense" | "income";
  createdAt?: Timestamp; // Firebase timestamp type
}

// Add a new transaction
export const addTransaction = async (
  transaction: Transaction
): Promise<void> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("User is not authenticated");

    const transactionRef = collection(db, "users", user.uid, "transactions");
    await addDoc(transactionRef, {
      ...transaction,
      createdAt: serverTimestamp(), // Set server timestamp for creation
    });

    console.log("Transaction added successfully");
  } catch (error) {
    console.error("Error adding transaction:", error);
    throw error;
  }
};

// Fetch all transactions for the current user
export const fetchTransactions = async (): Promise<Transaction[]> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("User is not authenticated");

    const transactionRef = collection(db, "users", user.uid, "transactions");
    const q = query(transactionRef, orderBy("date", "desc"));
    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
      const data = doc.data();

      // Validate and type the data explicitly
      const transaction: Transaction = {
        id: doc.id, // Add id property if needed
        amount: data.amount,
        date: data.date.toDate(), // Convert Firebase Timestamp to JavaScript Date
        category: data.category,
        description: data.description,
        type: data.type,
        createdAt: data.createdAt as Timestamp, // Ensure it's a Timestamp
      };

      return transaction;
    });
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

// Delete a transaction
export const deleteTransaction = async (
  transactionId: string
): Promise<void> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("User is not authenticated");

    const transactionDoc = doc(
      db,
      "users",
      user.uid,
      "transactions",
      transactionId
    );
    await deleteDoc(transactionDoc);

    console.log("Transaction deleted successfully");
  } catch (error) {
    console.error("Error deleting transaction:", error);
    throw error;
  }
};
