import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/navbar.css";
import { useAuth } from "../context/authContext"; // Use the custom hook for better type safety

const Navbar: React.FC = () => {
  const { currentUser, handleLogout } = useAuth(); // Access the currentUser and handleLogout from AuthContext
  const navigate = useNavigate();

  const handleLogoutAndNavigate = async () => {
    try {
      await handleLogout(); // Call the logout logic from context
      navigate("/login"); // Redirect to the login page
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <nav className="navbar">
      <h1>FluxeApp</h1>
      <ul>
        <li>
          <a href="/dashboard">Dashboard</a>
        </li>
        {currentUser ? (
          <li>
            <button
              onClick={handleLogoutAndNavigate}
              style={{ cursor: "pointer" }}
            >
              Logout
            </button>
          </li>
        ) : (
          <li>
            <a href="/login">Login</a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
