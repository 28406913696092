import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext"; // Use the custom hook for better type safety

interface PrivateRouteProps {
  children: ReactNode; // Represents the components rendered inside the protected route
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { currentUser } = useAuth(); // Access the currentUser from AuthContext

  // If the user is authenticated, render the children components (protected page)
  return currentUser ? <>{children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;
